import { config } from '@yodo/config'
import firebase from 'firebase/app'
import 'firebase/functions'

export default function getFunctionCallable(functionName: string) {
  return process.env.GATSBY_CONTEXT === 'local'
    ? firebase.functions().httpsCallable(functionName)
    : firebase
        .app()
        .functions(config.get('region'))
        .httpsCallable(functionName, { timeout: 540000 })
}
