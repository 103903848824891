import { Container, List } from '@material-ui/core'
import React, { ReactElement } from 'react'
import Layout from 'src/components/Layout'
import TopBar from 'src/components/Molecules/TopBar'
import Page from 'src/components/Page'
import PrivateRoute from 'src/components/PrivateRoute'
import ListDeals from 'src/components/Views/Deals/List'
import ListArchivedDeals from 'src/components/Views/Deals/ListArchived'
import commonStyles from 'src/styles/commonStyles'

/**
 * @constructor
 */
export default function DealsPage(): ReactElement {
  const commonClasses = commonStyles()

  return (
    <Layout>
      <Page>
        <PrivateRoute path="/login">
          <TopBar />
          <Container component="main" className={commonClasses.mainContentContainer}>
            <List component="nav">
              <ListDeals />
              <ListArchivedDeals />
            </List>
          </Container>
        </PrivateRoute>
      </Page>
    </Layout>
  )
}
