import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import ArchiveIcon from '@material-ui/icons/Archive'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import RestorePageIcon from '@material-ui/icons/RestorePage'
import { makeStyles } from '@material-ui/styles'
import { config } from '@yodo/config'
import { Deal, Merchant } from '@yodo/types'
import { navigate } from 'gatsby'
import { Button as GatsbyButton } from 'gatsby-material-ui-components/lib/button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from 'src/components/Molecules/Loading'
import ListItemDealDates from 'src/components/Views/Deals/ListItem/dates'
import ListItemDealInfo from 'src/components/Views/Deals/ListItem/infos'
import ListItemDealPrices from 'src/components/Views/Deals/ListItem/prices'
import SideDrawer from 'src/components/Views/SideDrawer'
import getFunctionCallable from 'src/utils/app/getFunctionCallable'
import { navigation } from 'src/utils/navigation'

type Props = {
  deal: Deal
  merchant?: Merchant
  listType?: string
}

export const ListItemDeal = ({ deal, merchant, listType }: Props) => {
  const [t] = useTranslation()
  const classes = useStyles()

  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [relaunchOpen, setRelaunchOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleArchiveClick = () => {
    setArchiveOpen(true)
  }

  const handleDuplicateClick = () => {
    setDuplicateOpen(true)
  }

  const handleDeleteClick = () => {
    setDeleteOpen(true)
  }

  const handleRelaunchClick = () => {
    setRelaunchOpen(true)
  }

  const handleClose = () => {
    setArchiveOpen(false)
    setDuplicateOpen(false)
    setDeleteOpen(false)
    setRelaunchOpen(false)
  }

  const handleDelete = async () => {
    setIsSubmitting(true)
    const deletedCollection =
      listType === 'archivedDeals' ? config.collection('archivedDeals') : config.collection('deals')
    const deleteDeal = getFunctionCallable('deleteDeal')
    await deleteDeal({
      dealId: deal.id,
      collection: deletedCollection
    })
      .then(() => {
        setIsSubmitting(false)
        setDeleteOpen(false)
      })
      .catch((error) => {
        console.error('Error removing document: ', error)
      })
  }

  const handleArchiveDeal = async () => {
    setIsSubmitting(true)
    const archiveDeal = getFunctionCallable('archiveDeal')
    await archiveDeal({
      dealId: deal.id
    })
      .then(() => {
        setIsSubmitting(false)
        setArchiveOpen(false)
      })
      .catch((error) => {
        console.error('Error archiving document: ', error)
      })
  }

  const handleRelaunch = async () => {
    setIsSubmitting(true)
    const relaunchDeal = getFunctionCallable('relaunchDeal')
    await relaunchDeal({
      dealId: deal.id
    })
      .then(() => {
        setIsSubmitting(false)
        setRelaunchOpen(false)
      })
      .catch((error) => {
        console.error('Error relaunching document: ', error)
      })
  }

  const getActionsButtons = (children: any) => {
    return isSubmitting ? (
      <DialogActions className={classes.loading}>
        <Loading />
      </DialogActions>
    ) : (
      children
    )
  }

  const getActionsDialog = () => {
    return (
      <>
        <Dialog open={deleteOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('confirm.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('confirm.sure?', { value: deal.name })}</DialogContentText>
          </DialogContent>
          {getActionsButtons(
            <DialogActions>
              <Button onClick={handleClose} disabled={isSubmitting}>
                {t('action.cancel')}
              </Button>
              <Button onClick={handleDelete} disabled={isSubmitting} color="primary">
                {t('action.delete')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
        <Dialog
          open={duplicateOpen}
          onClose={() => setDuplicateOpen(false)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('confirm.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('confirm.duplicate?', { value: deal.name })}</DialogContentText>
          </DialogContent>
          {getActionsButtons(
            <DialogActions>
              <Button onClick={() => setDuplicateOpen(false)} disabled={false}>
                {t('action.cancel')}
              </Button>
              <Button
                onClick={() => navigate(`${navigation.toDealDuplicate}${listType}/${deal.id}`)}
                disabled={false}
                color="primary">
                {t('action.duplicate')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
        <Dialog
          open={archiveOpen}
          onClose={() => setArchiveOpen(false)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('confirm.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('confirm.archive?', { value: deal.name })}</DialogContentText>
          </DialogContent>
          {getActionsButtons(
            <DialogActions>
              <Button onClick={() => setArchiveOpen(false)} disabled={isSubmitting}>
                {t('action.cancel')}
              </Button>
              <Button onClick={handleArchiveDeal} disabled={isSubmitting} color="primary">
                {t('action.archive')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
        <Dialog
          open={relaunchOpen}
          onClose={() => setRelaunchOpen(false)}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('confirm.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('confirm.relaunchDeal?', { value: deal.name })}
            </DialogContentText>
          </DialogContent>
          {getActionsButtons(
            <DialogActions>
              <Button onClick={() => setRelaunchOpen(false)} disabled={isSubmitting}>
                {t('action.cancel')}
              </Button>
              <Button onClick={handleRelaunch} disabled={isSubmitting} color="primary">
                {t('action.relaunchDeal')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </>
    )
  }

  return (
    <>
      <Grid
        key={deal.id}
        container
        spacing={3}
        style={{ paddingTop: '25px', borderBottom: '1px solid rgba(81, 81, 81, 1)' }}>
        <Grid item md={3} xs={6}>
          <ListItemDealInfo deal={deal} listType={listType} merchant={merchant} />
        </Grid>
        <Grid item lg={3} md={2} xs={6}>
          <ListItemDealDates deal={deal} />
        </Grid>

        <Grid item md={2} xs={6}>
          <ListItemDealPrices deal={deal} />
        </Grid>
        <Grid item lg={4} md={5} xs={12}>
          {listType === 'archivedDeals' ? (
            <div className={classes.actions}>
              <GatsbyButton
                variant="contained"
                color="primary"
                onClick={handleRelaunchClick}
                size="small"
                className={classes.btn}>
                <RestorePageIcon />
                {t('action.relaunchDeal')}
              </GatsbyButton>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                onClick={handleDuplicateClick}>
                <FileCopyIcon />
                {t('action.duplicate')}
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.btnError}
                onClick={handleDeleteClick}>
                <DeleteIcon />
              </Button>
              <SideDrawer values={deal} />
            </div>
          ) : (
            <div className={classes.actions}>
              <GatsbyButton
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                to={`${navigation.toDealEdit}${deal.id}`}>
                <EditIcon />
                {t('action.edit')}
              </GatsbyButton>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                onClick={handleDuplicateClick}>
                <FileCopyIcon />
                {t('action.duplicate')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btn}
                onClick={handleArchiveClick}>
                <ArchiveIcon />
                {t('action.archive')}
              </Button>
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                size="small"
                className={classes.btnError}
                onClick={handleDeleteClick}>
                <DeleteIcon />
              </Button>
              <SideDrawer values={deal} />
            </div>
          )}
        </Grid>
      </Grid>
      {getActionsDialog()}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    right: {
      width: '100%',
      textAlign: 'right'
    },
    breakWord: {
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    btn: {
      marginLeft: theme.spacing(2),
      ['& svg']: {
        marginRight: 8
      }
    },
    btnError: {
      marginLeft: theme.spacing(2),
      backgroundColor: theme.palette.error.main,
      ['& svg']: {
        color: 'white'
      },
      ['&:hover']: {
        backgroundColor: theme.palette.error.dark
      }
    },
    actions: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      ['& > *']: {
        marginBottom: 12
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        justifyContent: 'flex-start'
      }
    },
    loading: {
      marginBottom: 20
    }
  })
)
