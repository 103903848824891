import { Collapse, ListItem, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { config } from '@yodo/config'
import { Deal } from '@yodo/types'
import React, { useContext, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useTranslation } from 'react-i18next'
import Loading from 'src/components/Molecules/Loading'
import { ListItemDeal } from 'src/components/Views/Deals/ListItem'
import { FirebaseContext } from 'src/utils/firebase'

export default function ListDeals() {
  const [t] = useTranslation()
  const { firebase, token } = useContext(FirebaseContext)
  const [isDealListExpanded, setDealListExpanded] = useState(true)
  const merchantId = token?.claims?.merchantId ?? null

  const [deals, loading, error] = useCollectionData<Deal>(
    firebase
      .firestore()
      .collection(config.collection('deals'))
      .where(
        'merchant',
        '==',
        merchantId ? firebase.firestore().collection('merchants').doc(merchantId) : null
      ),
    {
      idField: 'id'
    }
  )

  if (loading) {
    return <Loading />
  }

  return (
    <>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}

      <ListItem button onClick={() => setDealListExpanded(!isDealListExpanded)}>
        <Typography variant={'h1'} style={{ marginRight: 'auto', marginTop: 20 }}>
          {t('deal.myOffers', { value: deals?.length })}
        </Typography>
        {isDealListExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isDealListExpanded} timeout="auto" unmountOnExit>
        {deals?.map((deal) => {
          return <ListItemDeal key={deal.id} deal={deal} listType={'deals'} />
        })}
        <br />
      </Collapse>
    </>
  )
}
