import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Deal } from '@yodo/types'
import React from 'react'
import { formatStringToDate } from 'src/utils/libs/date'

type Props = {
  deal: Deal
}

export default function ListItemDealDates({ deal }: Props) {
  const classes = useStyles()

  if (!deal.dateTimeStart) {
    return (
      <label className={classes.dateError}>
        Veuillez modifier les dates de début et fin de l'offre
      </label>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <label>Début de la vente</label>
        <strong>
          {deal.dateTimeStart &&
            formatStringToDate(new Date(deal.dateTimeStart!.seconds * 1000).toISOString(), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })}
        </strong>
      </div>

      <div className={classes.item}>
        <label>Fin de la vente</label>
        <strong>
          {deal.dateTimeEnd &&
            formatStringToDate(new Date(deal.dateTimeEnd!.seconds * 1000).toISOString(), {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            })}
        </strong>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%'
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'inherit',
        marginBottom: 10
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 25 + 12 + 12
      }
    },
    dateError: {
      color: theme.palette.error.main,
      width: '80%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 25 + 12 + 12,
        display: 'block'
      }
    }
  })
)
