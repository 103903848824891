import { createStyles } from '@material-ui/core'
import { VisibilityOff } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorIcon from '@material-ui/icons/Error'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { DateRangeIcon } from '@material-ui/pickers/_shared/icons/DateRangeIcon'
import { makeStyles } from '@material-ui/styles'
import { Deal, Merchant } from '@yodo/types'
import React, { useEffect, useState } from 'react'

type Props = {
  deal: Deal
  merchant?: Merchant
  listType: string | undefined
}

export default function ListItemDealInfo({ deal, merchant, listType }: Props) {
  const classes = useStyles()
  const [numberOfDays, setNumberOfDays] = useState<number>()
  const [showSubsidiaries, setShowSubsidiaries] = useState<boolean>(false)
  let quantityRemaining = 0
  if (deal.hasVariants) {
    deal.variants.forEach((variant) => {
      quantityRemaining = quantityRemaining + (variant.quantity - variant.quantityRemaining)
    })
  } else {
    quantityRemaining = deal.quantity - deal.quantityRemaining
  }

  useEffect(() => {
    const oneDay = 24 * 60 * 60 * 1000
    if (deal.dateTimeStart!) {
      setNumberOfDays(
        Math.round(
          (deal.dateTimeEnd!.toDate().valueOf() - deal.dateTimeStart!.toDate().valueOf()) / oneDay
        )
      )
    }
  }, [numberOfDays])

  useEffect(() => {
    if (merchant?.subsidiaries !== undefined && merchant?.subsidiaries.length > 1) {
      setShowSubsidiaries(true)
    }
  }, [merchant])

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        {deal.dateTimeStart ? (
          deal.dateTimeStart!.toDate().valueOf() <= Date.now() ? (
            listType === 'archivedDeals' ? (
              <VisibilityOff />
            ) : listType === 'deals' ? (
              <VisibilityIcon style={{ color: 'green' }} />
            ) : (
              <DeleteIcon />
            )
          ) : (
            <DateRangeIcon />
          )
        ) : (
          <ErrorIcon color={'primary'} />
        )}
      </div>
      <div className={classes.right}>
        <strong>{deal.name}</strong>
        <br />
        {showSubsidiaries && deal.subsidiaries && (
          <>
            <strong>{deal.subsidiaries[0].name}</strong>
            <br />
          </>
        )}
        {numberOfDays !== undefined && deal.dateTimeStart && listType === 'deals' ? (
          <>
            {numberOfDays === 0 && (
              <>
                Plus en ligne.
                <br />
              </>
            )}
            {numberOfDays === 1 && (
              <>
                Encore {numberOfDays} jour en ligne!
                <br />
              </>
            )}
            {numberOfDays > 1 && (
              <>
                Encore {numberOfDays} jours en ligne!
                <br />
              </>
            )}
          </>
        ) : null}
        {quantityRemaining === 0 && (
          <>
            <strong>Aucune vente réalisée</strong>
          </>
        )}
        {quantityRemaining === 1 && (
          <>
            <strong>{quantityRemaining}</strong> vente réalisée
          </>
        )}
        {quantityRemaining > 1 && (
          <>
            <strong>{quantityRemaining}</strong> ventes réalisées
          </>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex'
    },
    right: {
      paddingLeft: 25
    },
    icon: {
      width: 25
    }
  })
)
