import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Deal } from '@yodo/types'
import React from 'react'

type Props = {
  deal: Deal
}

export default function ListItemDealPrices({ deal }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <label>Prix avant offre</label>
        <strong>{parseFloat(String(deal.price)).toFixed(2)}</strong>
      </div>
      <div className={classes.item}>
        <label>Prix exclusif</label>
        <strong>{parseFloat(String(deal.priceWithDiscount)).toFixed(2)}</strong>
      </div>
      <div className={classes.item}>
        <label>Prix affiché</label>
        <strong>{parseFloat(String(deal.priceFinal)).toFixed(2)}</strong>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column'
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'inherit',
        marginBottom: 10
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 25 + 12 + 12
      }
    }
  })
)
